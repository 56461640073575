import { toTypedSchema } from '@vee-validate/zod';
import { formFormSchema } from '~/utils/schemas';

export function useFormForm() {
  const schema = computed(() => toTypedSchema(formFormSchema));

  const { form, handleSubmit, errors, resetForm, validate } = useAppForm({
    fields: [
      'form_template_id',
      'schemat_id',
      'medicine_id',
      'designation',
      'name',
      'description',
      'is_multiple_answer',
      'type',
      'start_date',
      'start_time',
      'end_date',
      'is_recurring',
      'recurring_type',
      'recurring_interval',
      'recurring_days_of_week',
      'send_for',
      'send_user_ids',
      'is_active',
      'form_questions',
      'survey_id',
    ],
    validationSchema: schema,
  });

  function handleResetForm() {
    resetForm();
    setDefaults();
  }

  function setDefaults() {
    // form.form_questions = [];
    // form.send_user_ids = [];
    // form.recurring_days_of_week = [];
    if (!form.medicine_id) {
      form.medicine_id = null;
    }
    if (typeof form.is_recurring !== 'boolean') {
      form.is_recurring = false;
    }
    if (typeof form.recurring_interval !== 'number') {
      form.recurring_interval = 1;
    }
    if (typeof form.type !== 'string') {
      form.type = 'own';
    }
    if (typeof form.recurring_type !== 'string') {
      form.recurring_type = null;
    }
    if (typeof form.send_for !== 'string') {
      form.send_for = 'users';
    }
    if (typeof form.is_active !== 'boolean') {
      form.is_active = true;
    }
    if (typeof form.is_multiple_answer !== 'boolean') {
      form.is_multiple_answer = false;
    }

    if (form.type === 'adverse_event') {
      form.is_multiple_answer = null;
      form.is_recurring = null;
      form.recurring_interval = null;
      form.recurring_type = null;
      form.send_for = null;
    }
  }

  handleResetForm();

  return {
    form,
    errors,
    resetForm: handleResetForm,
    handleSubmit,
    setDefaults,
    validate,
  };
}
